import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContentCard } from '../ContentCard/ContentCard'

import './announcement.scss'

export const Announcement = () => {
  const { t } = useTranslation('announcement')

  const maybeLines = t('lines', { returnObjects: true })

  const isStringArray = (data: unknown): data is string[] => {
    return (
      typeof data === 'object' &&
      Array.isArray(data) &&
      data.every((elem) => typeof elem === 'string')
    )
  }

  if (!isStringArray(maybeLines)) return null

  return (
    <ContentCard id="announcement" className="announcement">
      <div className="announcement-lines">
        {maybeLines.map((line) => {
          return <div>{line}</div>
        })}
      </div>
    </ContentCard>
  )
}
