import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SmoothLink } from '../SmoothLink/SmoothLink';
import { selectNavigationItem } from '../../store/navigation/actions';

import './links.scss';

export const Links: FC = () => {
  const { t } = useTranslation('footer');
  const dispatch = useDispatch();
  return (
    <div className="footer-links">
      <SmoothLink
        pathname="/"
        hash=""
        offset={0}
        className="link"
        onClick={() => dispatch(selectNavigationItem('HOME'))}
      >
        {t('title')}
      </SmoothLink>
      <SmoothLink
        pathname="/aboutUs"
        hash=""
        offset={0}
        className="link"
        onClick={() => dispatch(selectNavigationItem('ABOUT_US'))}
      >
        {t('aboutUs')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="menu"
        className="link"
        onClick={() => dispatch(selectNavigationItem('MENU'))}
      >
        {t('menu')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="availability"
        className="link"
        onClick={() => dispatch(selectNavigationItem('AVAILABLITY'))}
      >
        {t('availability')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="contact"
        className="link"
        onClick={() => dispatch(selectNavigationItem('CONTACT'))}
      >
        {t('contact')}
      </SmoothLink>
      <SmoothLink
        pathname="/imprint"
        hash=""
        offset={0}
        className="link"
        onClick={() => dispatch(selectNavigationItem('IMPRINT'))}
      >
        {t('imprint')}
      </SmoothLink>
    </div>
  );
};
