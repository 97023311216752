import { NavigationActionTypes, NavigationState, SELECT_MENU } from './types';

const initialState: NavigationState = {
  selectedNavigationItem: 'HOME',
};

export function navigationReducer(
  state = initialState,
  action: NavigationActionTypes
): NavigationState {
  switch (action.type) {
    case SELECT_MENU:
      return {
        selectedNavigationItem: action.payload,
      };
    default:
      return state;
  }
}
