import React, { FC, useState } from 'react';

import { Items } from './Items';

import menu from '../../assets/icons/menu.svg';

import './mobile.scss';

export const Mobile: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const closeOverlay = () => {
    if (showOverlay) {
      setShowOverlay(false);
    }
  };

  return (
    <>
      <button
        className="button button--white mobile-menu-toggle"
        onClick={() => setShowOverlay(!showOverlay)}
      >
        <img src={menu} alt="menu" />
      </button>
      {showOverlay && (
        <div className="mobile-menu-overlay">
          <Items className="mobile-menu-items" onClick={closeOverlay} />
        </div>
      )}
    </>
  );
};
