import { MemoryRouter, Route } from 'react-router-dom';
import { AboutUs } from '../AboutUs/AboutUs';
import { Footer } from '../Footer/Footer';

import { Header } from '../Header/Header';
import { Imprint } from '../Imprint/Imprint';
import { Main } from '../Main/Main';

import './app.scss';

export const App = () => {
  const main = () => <Main />;
  const aboutUs = () => <AboutUs />;
  const imprint = () => <Imprint />;

  return (
    <div className='App'>
      <MemoryRouter>
        <>
          <Header />
          <Route path='/' exact component={main} />
          <Route path='/aboutUs' component={aboutUs} />
          <Route path='/imprint' component={imprint} />
          <Footer />
        </>
      </MemoryRouter>
    </div>
  );
};
