export type MenuItem =
  | 'HOME'
  | 'ABOUT_US'
  | 'MENU'
  | 'AVAILABLITY'
  | 'CONTACT'
  | 'IMPRINT';

export interface NavigationState {
  selectedNavigationItem: MenuItem;
}

export const SELECT_MENU = 'SELECT_MENU';

interface SelectMenuAction {
  type: typeof SELECT_MENU;
  payload: MenuItem;
}

export type NavigationActionTypes = SelectMenuAction;
