import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Frame } from "../Frame/Frame";
import { GoogleMaps } from "../GoogleMaps/GoogleMaps";
import { Image } from "../Image/Image";

import lieferandoPng from "../../assets/icons/lieferando_b.png";
import lieferandoWebp from "../../assets/icons/lieferando_b.webp";
import phone from "../../assets/icons/phone.svg";
// import email from "../../assets/icons/mail.svg";

import "./contact.scss";

export const Contact: FC = () => {
  const { t } = useTranslation("contact");
  return (
    <Frame id="contact" className="contact">
      <div className="contact-content">
        <div className="contact-title title">{t("title")}</div>
        <div className="contact-address">
          <div className="contact-subtitle font-big">{t("address")}</div>
          <div>Occamstraße 11</div>
          <div>80802 {t("cityName")}</div>
        </div>
        {/* <div className="contact-email">
          <div className="contact-subtitle font-big">{t('email')}</div>
          <a
            className="button button--white contact-btn contact-email-btn"
            href="mailto:info@60-secondi.de"
          >
            <img src={email} alt="email" />
            info@60-secondi.de
          </a>
        </div> */}
        <div className="contact-phone">
          <div className="contact-subtitle font-big">{t("phone")}</div>
          <a
            className="button button--white contact-btn contact-phone-btn"
            href="tel:+4908938899177"
          >
            <img src={phone} alt="phone" />
            089 - 388 99 177
          </a>
        </div>
        <div className="contact-reservation">
          <div className="contact-subtitle font-big">{t("reservation")}</div>
          <div className="contact-reservation-buttons">
            <a
              className="button button--white contact-btn contact-phone-btn"
              href="tel:+4908938899177"
            >
              <img src={phone} alt="phone" />
              089 - 388 99 177
            </a>
            <a
              className="button button--white contact-btn"
              href="https://www.lieferando.de/60-secondi-pizza-napoletana"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                defaultSource={[{ src: lieferandoWebp, type: "webp" }]}
                fallbackSource={[{ src: lieferandoPng, type: "png" }]}
                alt="60-Secondi lieferando"
              />
            </a>
          </div>
          <div className="font-small">{t("area")}</div>
        </div>
      </div>
      <div className="contact-map">
        <GoogleMaps />
      </div>
    </Frame>
  );
};
