import { HashLink } from 'react-router-hash-link';

import './smoothLink.scss';

type Props = {
  pathname: string;
  hash: string;
  children: React.ReactNode;
  onClick?(): void;
  offset?: number;
  className?: string;
};

const scrollWithOffset = (el: { offsetTop: number }, offset: number) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: 'smooth',
  });
};

export const SmoothLink: React.FC<Props> = ({
  children,
  onClick,
  pathname,
  hash,
  offset,
  className,
}) => {
  return (
    <HashLink
      smooth
      to={`${pathname}#${hash}`}
      scroll={(el) => scrollWithOffset(el, offset || 120)}
      onClick={onClick}
      className={className}
    >
      {children}
    </HashLink>
  );
};
