import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Image } from '../Image/Image';
import { SmoothLink } from '../SmoothLink/SmoothLink';
import { navigationItemSelector } from '../../store/navigation/selector';
import { MenuItem } from '../../store/navigation/types';
import { selectNavigationItem } from '../../store/navigation/actions';

import facebookPng from '../../assets/icons/facebook_b.png';
import facebookWebp from '../../assets/icons/facebook_b.webp';
import lieferandoPng from '../../assets/icons/lieferando_b.png';
import lieferandoWebp from '../../assets/icons/lieferando_b.webp';
import instagramPng from '../../assets/icons/instagram_b.png';
import instagramWebp from '../../assets/icons/instagram_b.webp';

type ItemsProps = {
  onClick?: () => void;
  className?: string;
};

export const Items: FC<ItemsProps> = ({ onClick, className }) => {
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const selectedNavigationItem = useSelector(navigationItemSelector);

  const getButtonColor = (item: MenuItem) =>
    selectedNavigationItem === item ? 'button--black' : 'button--white';

  const onButtonClick = (item: MenuItem) => {
    dispatch(selectNavigationItem(item));
    onClick && onClick();
  };

  return (
    <div className={className}>
      <SmoothLink
        pathname="/"
        hash="menu"
        onClick={() => onButtonClick('MENU')}
        className={`button ${getButtonColor('MENU')}`}
      >
        {t('menu')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="availability"
        onClick={() => onButtonClick('AVAILABLITY')}
        className={`button ${getButtonColor('AVAILABLITY')}`}
      >
        {t('availability')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="contact"
        onClick={() => onButtonClick('CONTACT')}
        className={`button ${getButtonColor('CONTACT')}`}
      >
        {t('contact')}
      </SmoothLink>
      <SmoothLink
        pathname="/aboutUs"
        hash=""
        offset={0}
        onClick={() => onButtonClick('ABOUT_US')}
        className={`button ${getButtonColor('ABOUT_US')}`}
      >
        {t('aboutUs')}
      </SmoothLink>
      <div className="external">
        <a
          className="button button--white"
          href="https://www.facebook.com/60secondipizza/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            defaultSource={[{ src: facebookWebp, type: 'webp' }]}
            fallbackSource={[{ src: facebookPng, type: 'png' }]}
            alt="60-Secondi facebook"
          />
        </a>
        <a
          className="button button--white"
          href="https://www.instagram.com/60secondi_pizza/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            defaultSource={[{ src: instagramWebp, type: 'webp' }]}
            fallbackSource={[{ src: instagramPng, type: 'png' }]}
            alt="60-Secondi instagram"
          />
        </a>
        <a
          className="button button--white"
          href="https://www.lieferando.de/60-secondi-pizza-napoletana"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            defaultSource={[{ src: lieferandoWebp, type: 'webp' }]}
            fallbackSource={[{ src: lieferandoPng, type: 'png' }]}
            alt="60-Secondi lieferando"
          />
        </a>
      </div>
    </div>
  );
};
